import { AxiosResponse, AxiosError } from "axios";
import { $authHost } from "./api";
import { IFetchRolesResponse, IFetchEmployeesRolesPayload, IEmployeesRolesResponse } from "@/types/employeeTypes";

interface IExportStatisticsParams {
  date_from: string
  date_to: string
  employee_role_id: number
  employees: number[]
}

interface IExportStatisticsPayload {
  token: string
  params: IExportStatisticsParams
}

class EmployeeStatisticsService {
  async fetchRoles( token: string ) {
    const { data }: AxiosResponse<IFetchRolesResponse> = await $authHost.get('/employee/role/names',
    {
      headers: {
        Authorization: 'Bearer ' + token,
      }
    })
    return data.result
  }

  async fetchEmployeesRoles(payload: IFetchEmployeesRolesPayload) {
    const { token, roleId } = payload
    const { data }: AxiosResponse<IEmployeesRolesResponse> = await $authHost.get(`/employee/role/${roleId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      }
    })
    return data.result
  }

  async exportStatistics(payload: IExportStatisticsPayload) {
    try {
      const { data }: AxiosResponse = await $authHost.get('statistic/export/employee',
      {
        params: payload.params,
        headers: {
          Authorization: 'Bearer ' + payload.token,
        },
        responseType: 'blob',
      })

      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/vnd.ms-excel',
        })
      )
      const link = document.createElement('a')
      link.href = url
      const filename = payload.params.date_from === payload.params.date_to ? `manager_statistics_${payload.params.date_from}` : `manager_statistics_from_${payload.params.date_from}_to_${payload.params.date_to}`
      link.setAttribute(
        'download',
        filename + '.xlsx'
      )
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('exportStatisticsServiceError', error.response);
      console.log('====================================');
    }
  }
}

export default new EmployeeStatisticsService
