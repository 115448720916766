import { defineStore } from "pinia"
import { ref } from 'vue'
import { IFetchRoles, IEmployeesRoles, IFetchEmployeesRolesPayload } from "@/types/employeeTypes"
import employeeService from "@/services/employeeService"
import { AxiosError } from "axios"

export const useEmployeeStore = defineStore('employeeStore', () => {
  const employeeRoles = ref<IFetchRoles[]>([])
  const employees = ref<IEmployeesRoles[]>([])

  const fetchRoles = async (token: string) => {
    try {
      const response: IFetchRoles[] = await employeeService.fetchRoles(token)
      employeeRoles.value = response
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('fetchRolesERROR', error.response);
      console.log('====================================');
    }
  }

  const fetchEmployees = async (payload: IFetchEmployeesRolesPayload) => {
    try {
      const response: IEmployeesRoles[] = await employeeService.fetchEmployeesRoles(payload)
      employees.value = response
    } catch (e) {
      const error = e as AxiosError
      console.log('====================================');
      console.log('fetchEmployeesERROR', error.response);
      console.log('====================================');
    }
  }

  return {
    employeeRoles,
    employees,
    fetchRoles,
    fetchEmployees
  }
})
